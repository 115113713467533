import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
//import Twitter from './Twitter';
//import Facebook from './Facebook';

const SEO = ({
               title = null,
               description = null,
               keywords = null,
               pathname = null,
               article = false,
               showSchema = false,
               breadcrumbs = null,
               rating = null,
               gameTitle = null,
               socialImage = "/logo.png",
             }) => (
  <StaticQuery
    query={graphql`
      query SEOQuery {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
             twitterUsername: twitterUsername
            facebookAppID: facebookAppID
          }
        }
      }
    `}
    render={({
               site: {
                 siteMetadata: {
                   defaultTitle,
                   defaultDescription,
                   siteUrl,
                   twitterUsername,
                   facebookAppID,
                 },
               },
             }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${socialImage}`,
        siteUrl: `${siteUrl}${pathname || '/'}`,
      };

      //console.log('Seo rendering...');

      const breadcrumbsArray = breadcrumbs ? [] : null;
      let breadcrumbPosition = 1;
      if (breadcrumbs) {
        breadcrumbs.forEach(function(element) {
          let url = element.slug ? "https://id.oppa888.com/" + element.slug + "/" : "https://id.oppa888.com/";
          let breadcrumbRepresentation = {
            "@type": "ListItem",
            "position": breadcrumbPosition,
            "name": element.title,
            "item": url,
          };
          breadcrumbsArray.push(JSON.stringify(breadcrumbRepresentation));
          breadcrumbPosition++;
        });
      }

      return (
        <Helmet title={seo.title} defer={false}>
          <html key="app-lang" lang="id-id"/>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="description" content={seo.description}/>
          <meta name="google-site-verification" content="R33HdCoaNOswVWQZQBEdN35B9yHeB6F17pDI1fCUtwM" />
          <meta key="app-viewport" name="viewport" content="initial-scale=1.0, width=device-width"/>
          <meta name="robots" content="all,noodp"/>
          <meta name="googlebots" content="all,noodp"/>
          <meta name="revisit-after" content="1 days"/>
          <link rel="preconnect" href="https://stats.g.doubleclick.net" />
          <link rel="preconnect" href="https://www.google.com" />
          <link rel="canonical" href="https://id.oppa888.com/" />
          <link rel="alternate" href="https://id.oppa888.com/" hrefLang="id-id"/>
          <meta name="image" content={seo.image}/>
          {keywords ? <meta name="keywords" content={keywords} /> : null}

          {/* OpenGraph tags */}
          <meta property="og:url" content={seo.siteUrl} />
          {article ? <meta property="og:type" content="article" /> : null}
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
          {facebookAppID && <meta property="fb:app_id" content={facebookAppID}/>}

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />

          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1004135356686572');
            fbq('track', 'PageView');`}
          </script>
          <noscript>{`
           <img alt="FB" height="1" width="1" style="display:none"
                         src="https://www.facebook.com/tr?id=1004135356686572&ev=PageView&noscript=1"/>`}
           </noscript>


          {showSchema &&
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "@id":"#website",
                "url":"${siteUrl}${pathname || '/'}",
                "name": "${seo.title}",
                "potentialAction": {"@type":"SearchAction","target":"https:\\/\\/id.oppa888.com\\/?s={search_term_string}","query-input": "required name=search_term_string"}
              }
            `}
          </script>
          }
          {breadcrumbsArray &&
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${breadcrumbsArray}]
            }
            `
            }
          </script>
          }
          {rating && gameTitle &&
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "Brand",
              "name": "${gameTitle}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "bestRating": "5",
                "ratingCount": "74",
                "ratingValue": "${rating}"
              }
            }
            `
            }
          </script>
          }
        </Helmet>
      );
    }}
  />
);

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  showSchema: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  rating: PropTypes.number,
  gameTitle: PropTypes.string,
  socialImage: PropTypes.string,
};

export default SEO;
