import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import Img from 'gatsby-image';
//import HomePageContext from "../../../components/shared/Context/HomePageContext";
import IcomoonIcon from '../../shared/IcomoonIcon';
import PopupIframe, { invokePopup } from '../../shared/PopupIframe';
import LoginForm from '../../Auth/Form/LoginForm';
//import RegistrationForm from '../../Auth/Form/RegistrationForm';
import StoreContext from '../../shared/Context/StoreContext';
import StarsHome from './StarsHome';

const PlayForFreeWrap = styled('div')`
  .title-block {
    position: relative;
    margin: 9rem 0 1rem;
  }
  .h1 {
    margin: 0 auto 40px;
    text-align: center;
  }
  @media (max-width: ${breakpoints.md}) {
    .title-block {
      margin: 3.5rem 0 1rem;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .h1 {
      margin-bottom: 0.7rem;
    }
  }
`;

const PlayForFreeAll = styled('div')`
  clear: both;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const PlayForFreeVideo = styled('div')`
  flex-basis: 32%;
  background: ${color.darkRed};
  height: 98.5%;
  position: relative;
  border-radius: 10px;
  .play-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -45px 0 0 -55px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    span {
      display: inline-block;
      width: 1px;
      text-indent: -9999px;
    }
    &:hover,
    &:active {
      svg {
        path {
          fill: ${color.orange} !important;
        }
      }
    }
  }
  .terms-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 60px 0 0 -100px;
    width: 200px;
    z-index: 1;
    visibility: hidden;
  }
  img {
    border-radius: 15px;
  }
  &:hover,
  &:active {
    .terms-button,
    .play-button {
      visibility: visible;
    }
    img {
      filter: brightness(20%);
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .terms-button,
    .play-button {
      visibility: visible;
    }
    .play-button {
      top: 38%;
    }
    .terms-button {
      top: 40%;
    }
    img {
      filter: brightness(20%);
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-basis: 100%;
    margin: 0 5px;
  }
`;

const GameAds = styled('div')`
  flex-basis: 68%;
  text-align: right;
  @media (max-width: ${breakpoints.sm}) {
    margin: 12px 5px 0;
    flex-basis: 100%;
  }
`;

const GameAd = styled('div')`
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 3.2% 3.3%;
  position: relative;
  .game-title {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: none;
    position: absolute;
    top: 1%;
    left: 0;
    z-index: 1;
    color: ${color.white};
  }
  .show-more,
  .coming-soon {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -60px;
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .ribbon {
    position: absolute;
    top: 12%;
    right: -5px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.orange};
    color: ${color.midRed};
    font-weight: bold;
    border-radius: 4px;
  }
  &:hover,
  &:active {
    .ribbon {
      display: none;
    }
    .coming-soon,
    .show-more,
    .game-title {
      display: inline-block;
    }
  }
  a,
  .a {
    display: block;
    &:hover,
    &:active {
      img {
        filter: brightness(20%);
      }
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: calc(50% - 12px);
    margin: 12px 0;
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }
    .coming-soon {
      font-size: 0.8rem;
    }
  }
`;

const PlayForFree = ({ title, iframe, video_cover, temp }) => {
  const storeValue = useContext(StoreContext);
  const username = storeValue.username;

  const gameClick = (e, slide_title, game_title, slide_description) => {
    if (!username) {
      e.preventDefault();
      invokePopup(e, 'login-popup-window');
    }
    if (slide_description) {
      return false;
    }
  };

  const videoClick = (e, iframeSrc) => {
    const homeVideoIframe = document.getElementById('free-spin-home-iframe');
    homeVideoIframe.src = iframeSrc;
    invokePopup(e, 'free-spin-home-popup');
  };

  return (
    <PlayForFreeWrap id="home-play-for-free" className="home-play-for-free">
      <div className="title-block">
        <h1 className="h1 rich-design a-center">
          <span>{title}</span>
        </h1>
        <StarsHome />
      </div>
      <PlayForFreeAll>
        <PlayForFreeVideo>
          {iframe && (
            <button
              className="play-button"
              onClick={e => videoClick(e, iframe)}
            >
              <IcomoonIcon icon="play-button" color={color.white} size={90} />
              <span>Play</span>
            </button>
          )}
          <a
            href={process.env.FREESPIN_TERMS_URL_RELATIVE}
            className="button transparent-button terms-button"
          >
            Syarat dan Ketentuan
          </a>
          {video_cover &&
            video_cover.alt_text &&
            video_cover.localFile &&
            video_cover.localFile.childImageSharp && (
              <Img
                fluid={video_cover.localFile.childImageSharp.fluid}
                alt={video_cover.alt_text}
              />
            )}
        </PlayForFreeVideo>
        <GameAds>
          {temp.map(item => {
            const localFile = item.localFile || {};
            return (
              <GameAd key={item.path}>
                {item.acf.slide_title && (
                  <span className="ribbon">{item.acf.slide_title}</span>
                )}
                {item.acf.slide_description && (
                  <span className="coming-soon">
                    {item.acf.slide_description}
                  </span>
                )}
                {item.acf.slide_url ? (
                  <a
                    onClick={e => {
                      gameClick(
                        e,
                        item.title,
                        item.alt_text,
                        item.acf.slide_description
                      );
                    }}
                    href={item.acf.slide_url}
                    title={item.alt_text}
                  >
                    {item.alt_text && (
                      <span className="game-title">{item.alt_text}</span>
                    )}
                    {!item.acf.slide_description && (
                      <span className="button gradient-button show-more">
                        Play
                      </span>
                    )}
                    {localFile.childImageSharp &&
                      localFile.childImageSharp &&
                      item.alt_text && (
                        <Img
                          fluid={localFile.childImageSharp.fluid}
                          alt={item.alt_text}
                        />
                      )}
                  </a>
                ) : (
                  <div className="a">
                    {localFile.childImageSharp &&
                      localFile.childImageSharp &&
                      item.alt_text && (
                        <Img
                          fluid={localFile.childImageSharp.fluid}
                          alt={item.alt_text}
                        />
                      )}
                  </div>
                )}
              </GameAd>
            );
          })}
        </GameAds>
        {iframe && (
          <PopupIframe
            title="Free Spin"
            popupId="free-spin-home-popup"
            extraClass="wide"
          >
            <div className="iframe-container">
              <iframe
                id="free-spin-home-iframe"
                title="Free Spin"
                src="about:blank"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                allowFullScreen="allowFullScreen"
                frameBorder={0}
              />
            </div>
          </PopupIframe>
        )}
        {!username && (
          <div>
            <LoginForm popupId="login-popup-window" />
            {/*<RegistrationForm popupId="home-registration-popup" />*/}
          </div>
        )}
      </PlayForFreeAll>
    </PlayForFreeWrap>
  );
};

export default PlayForFree;
