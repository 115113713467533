import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import Step from '../../../icons/svg/Step';
import Deposit from '../../../icons/svg/Deposit';
import Register from '../../../icons/svg/Register';
import Enjoy from '../../../icons/svg/Enjoy';

const WrapperSection = styled('div')`
  margin: 30px 0;
  height: 120px;
  background: rgba(108,17,15,.3);
  border-radius: 20px;
  position: relative;
  .step-block {
    position: absolute;
    top: 0;
    height: 100%;
    &:hover, &:active, &:focus {
      color: ${color.white};
      text-decoration: none;
      text-shadow: 0 0 9px ${color.peach};
      .step-title {
        color: ${color.white};
      }
    }
  }
  .step-title {
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${color.peach};
    text-shadow: 0 0 9px ${color.peach};
  }
  .step-details {
    font-size: 1rem;
  }
  .content-wrap {
    display: flex;
    align-items: center;
    svg {
      pointer-events: none;
      filter: drop-shadow(0 0 3px ${color.peach});
    }
  }
  .step-icon {
    position: absolute;
  }
  .step-icon-1 {
    display: none;
  }
  .step-icon-2, .step-icon-3 {
    right: 108%;
  }
  .text-block {
    position: relative;
    z-index: 1;
  }
  
  @media (min-width: ${breakpoints.mdMin}) {
    .step-icon-1 {
      animation: run linear infinite 5s;
      display: block; 
    }
    @keyframes run {
      0% { left: -50%;}
      100%{ left : 280%;}
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    position: static;
    height: auto;
    overflow: hidden;
    .step-block {
      position: relative;
      left: 0;
      top: auto;
      display: block;
      width: 100%;
    }
    .step-icon {
      right: auto;
      left: -70px;
      display: block;
    }
    .content-wrap {
      svg {
        width: 80px !important;
        margin: 0 60px 0 10px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    border-radius: 10px;
    margin: 10px 0;
    .step-icon {
      left: -80px;
      svg { 
        height: 66px; 
      }
    }
    .content-wrap {
      height: 66px;
      svg {
        width: 57px !important;
        margin: 0 33px 0 10px !important;
      }
      .text-block {
        width: calc(100% - 50px);
      }
    }
    
    .step-title {
      font-size: 14px;
      margin: 5px 0 0;
    }
    .step-details {
      font-size: 13px;
      display: block;
      width: 92%;
      line-height: 1.1;
    }
  }
`;



const Step1Wrap = styled('a')`
  width: 28%;
  left: 3%;
  .content-wrap {
    svg {
      margin: 0 5px 0 -15px;
    }
  }

  
`;

const Step2Wrap = styled('a')`
  width: 30%;
  top: 10px;
  left: 35%;
  padding: 0 3%;
  cursor: pointer;
  .content-wrap {
    svg {
      margin: 0 10px 0 -50px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    .content-wrap {
      svg {
        width: 60px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .content-wrap {
      svg {
        width: 60px !important;
      }
    }
  }
 
`;

const Step3Wrap = styled('a')`
  width: 28%;
  top: 10px;
  left: 69%;
  .content-wrap {
    svg {
      margin: 0 10px 0 -10px;
    }
  }
  
  
`;



const Steps = () => (
  <WrapperSection>
    <Step1Wrap className="step-block" href={"/register/"}>
      <div className="step-icon step-icon-1">
        <Step id="step-icon-1"/>
      </div>

      <div className="content-wrap content-wrap-step-1">
        <Register width={104} height={120}/>

        <div className="text-block">
          <span className="step-title">1. Daftar</span>
          <span className="step-details">Hubungi kami di Live Chat</span>
        </div>
      </div>
    </Step1Wrap>

    <Step2Wrap className="step-block" href={"/register/"}>
      <div className="step-icon step-icon-2">
        <Step id="step-icon-2"/>
      </div>
      <div className="content-wrap">
        <Deposit width={130} height={120}/>
        <div className="text-block">
          <span className="step-title">2. Aktivasi Akun</span>
          <span className="step-details">Cek e-mail dan klik link verifikasi</span>
        </div>
      </div>
    </Step2Wrap>

    <Step3Wrap className="step-block no-desktop-arrow" href={"/register/"}>
      <div className="step-icon step-icon-3">
        <Step id="step-icon-3"/>
      </div>
      <div className="content-wrap no-desktop-arrow content-wrap-step-3">
        <Enjoy width={130} height={120}/>
        <div className="text-block">
          <span className="step-title">3. Selamat Bermain</span>
          <span className="step-details">Dapatkan username dan password untuk bermain sekarang</span>
        </div>
      </div>
    </Step3Wrap>
  </WrapperSection>
);


export default Steps;