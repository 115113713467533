import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import { Slick } from '../utils/style';
import SlickSlide from '../components/shared/SlickSlideNew';
import { breakpoints, ContentStyles } from '../utils/style';
import Steps from '../components/Content/Home/StepsNew';
import PlayForFree from '../components/Content/Home/PlayForFree';
import Demo10Games from '../components/Content/Home/SlotGames';
import WithdrawCommentsStaticLoadmore from '../components/Content/Withdraw/Comments/CommentsStaticLoadmore';
import LazyLoad from 'react-lazyload';
import withLocation from '../components/shared/withLocation';
import Parallax from '../components/shared/Parallax';
import GetWindowWidth from '../components/shared/GetWindowWidth';

/*const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} aria-label="Next">
      <IcomoonIcon icon="gclub-right" color={color.trueBlack} size={30} />
    </button>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} aria-label="Previous">
      <IcomoonIcon icon="gclub-left" color={color.trueBlack} size={30} />
    </button>
  );
};*/

const ContentWrapper = styled('div')`
  text-align: center;
  .show-more {
    .more-span {
      display: inline;
    }
    .less-span {
      display: none;
    }
  }
  .show-less {
    .more-span {
      display: none;
    }
    .less-span {
      display: inline;
    }
  }
  .home-text-content {
    max-height: 190px;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 20px;
  }
  .full-height {
    max-height: 2000px;
  }
  .home-image-flex {
    svg {
      width: 150px;
    }
    .col-left {
      flex: 1;
    }
    .col-right {
      flex: 0;
      padding-left: 20px;
    }
  }

  ${ContentStyles};
  @media (max-width: ${breakpoints.sm}) {
    .h3 {
      margin-top: 0;
    }
    .home-text-content {
      max-height: 420px;
    }
    .full-height {
      max-height: 3000px;
    }
    .home-image-flex {
      display: block;
      .col-right {
        text-align: center;
        padding: 10px;
      }
    }
  }
`;

const SlickWrapper = styled('div')`
  padding-bottom: 5px;
  max-width: 1225px;
  margin: 0 auto;
  .gatsby-image-wrapper {
    max-width: 100%;
  }
  .slick-dots {
    bottom: 10px;
  }
  .desktop-view {
    margin: 0 -345px;
    .slick-slide {
      padding: 0 30px;
      opacity: 0.3;
    }
    .slick-active {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      border-radius: 30px;
    }
  }
  .mobile-view {
    display: none;

    .slick-slide {
      padding: 0 5px;
      opacity: 0.5;
    }
    .slick-active {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
  .mobile-vew-one {
    padding: 0 15px;
  }
  ${Slick};
  @media (max-width: ${breakpoints.xl}) {
    .desktop-view {
      margin: 0 -350px;
      &.no-padding {
        margin: 0 -10px;
      }
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .desktop-view {
      margin: 0 -320px;
      &.no-padding {
        margin: 0;
      }
      .slick-slide {
        padding: 0 10px;
      }
    }
  }

  @media (max-width: 1000px) {
    .desktop-view {
      margin: 0 -300px;
      &.no-padding {
        margin: 0;
      }

      .slick-slide {
        padding: 0 5px;
      }
    }
  }
  @media (max-width: 750px) {
    .mobile-view {
      display: block;
    }
    .desktop-view {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .slick-dots {
      bottom: 0;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .slick-prev,
    .slick-next {
      display: none;
    }
  }
`;

const WrapperDesktop = styled('div')`
  max-width: 1244px;
  margin: 0 auto;
  padding: 5px 10px;
`;

const WrapperPadded = styled('div')`
  max-width: 1244px;
  margin: 0 auto;
  padding: 5px 10px;
  @media (max-width: ${breakpoints.md}) {
    padding: 5px 15px;
  }
`;

const HomePage = ({ data, search, navigate }) => {
  const windowWidth = GetWindowWidth();
  const isDesktop = windowWidth > 750;
  const homePageNode = data.allWordpressPage.edges[0].node;
  const featuredMedia = homePageNode.featured_media;
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.OWNER;
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : '';
  const socialImage = homePageNode.featured_media?.localFile?.publicURL;
  const slider = homePageNode.acf.top_slider;
  const sliderMobile = homePageNode.acf.top_slider_mobile;
  const showSlider = slider && slider.length > 1;
  const showAsBanner = slider && slider.length === 1;
  const showSliderMobile =
    sliderMobile && sliderMobile.length > 1 && !isDesktop;
  const showAsBannerMobile = sliderMobile && sliderMobile.length === 1;
  const showPlayForFree = homePageNode.acf.play_for_free_temp;
  const showDemoGames = homePageNode.acf.demo_games_10.length > 0;
  const title = homePageNode.acf.play_for_free_title;
  const iframe = homePageNode?.acf?.play_for_free_video_file_id;
  const video_cover = homePageNode.acf.play_for_free_video_cover;
  const sliderSettings = {
    dots: true,
    arrows: false,
    centerMode: true,
    centerPadding: '15px',
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };
  const sliderSettingsDesktop = {
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '315px',
    dots: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const handleMoreClick = e => {
    e.preventDefault();
    const target = document.getElementById('home-text-content');
    target.classList.toggle('full-height');
    const self = document.getElementById('home-more-less');
    self.classList.toggle('show-less');
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        showSchema={true}
        keywords="GCLUB Casino, คาสิโนออนไลน์, เว็บพนันออนไลน์, บาคาร่าออนไลน์, สล็อตออนไลน์, สมัคร GCLUB, ทางเข้า GCLUB"
        socialImage={socialImage}
      />
      <Parallax />
      <div id="home-content-all" className="with-overflow-slider">
        {showSlider && !showAsBanner && (
          <SlickWrapper>
            <div className="desktop-view">
              <Slider {...sliderSettingsDesktop}>
                {slider.map(item => (
                  <SlickSlide
                    key={item.path}
                    img={item.localFile}
                    altText={item.alt_text}
                    line1={item.acf ? item.acf.slide_title : ''}
                    line2={item.acf ? item.acf.slide_description : ''}
                    link={item.acf ? item.acf.slide_url : ''}
                    ext={item.acf && item.acf.slide_url.indexOf('http') !== -1}
                    anchor={item.acf && item.acf.slide_url.indexOf('#') !== -1}
                  />
                ))}
              </Slider>
            </div>
            {showSliderMobile && (
              <div className="mobile-view">
                <LazyLoad once={true}>
                  <Slider {...sliderSettings}>
                    {sliderMobile.map(item => (
                      <SlickSlide
                        key={item.path}
                        img={item.localFile}
                        altText={item.alt_text}
                        line1={item.acf ? item.acf.slide_title : ''}
                        line2={item.acf ? item.acf.slide_description : ''}
                        link={item.acf ? item.acf.slide_url : ''}
                        ext={
                          item.acf && item.acf.slide_url.indexOf('http') !== -1
                        }
                        anchor={
                          item.acf && item.acf.slide_url.indexOf('#') !== -1
                        }
                      />
                    ))}
                  </Slider>
                </LazyLoad>
              </div>
            )}
          </SlickWrapper>
        )}

        {showAsBanner && (
          <SlickWrapper>
            <div className="desktop-view no-padding">
              <WrapperDesktop>
                {slider.map(item => (
                  <SlickSlide
                    key={item.path}
                    img={item.localFile}
                    altText={item.alt_text}
                    line1={item.acf ? item.acf.slide_title : ''}
                    line2={item.acf ? item.acf.slide_description : ''}
                    link={item.acf ? item.acf.slide_url : ''}
                    ext={item.acf && item.acf.slide_url.indexOf('http') !== -1}
                    anchor={item.acf && item.acf.slide_url.indexOf('#') !== -1}
                  />
                ))}
              </WrapperDesktop>
            </div>

            {showAsBannerMobile && (
              <div className="mobile-view mobile-vew-one">
                <LazyLoad once={true}>
                  {sliderMobile.map(item => (
                    <SlickSlide
                      key={item.path}
                      img={item.localFile}
                      altText={item.alt_text}
                      line1={item.acf ? item.acf.slide_title : ''}
                      line2={item.acf ? item.acf.slide_description : ''}
                      link={item.acf ? item.acf.slide_url : ''}
                      ext={
                        item.acf && item.acf.slide_url.indexOf('http') !== -1
                      }
                      anchor={
                        item.acf && item.acf.slide_url.indexOf('#') !== -1
                      }
                    />
                  ))}
                </LazyLoad>
              </div>
            )}
          </SlickWrapper>
        )}

        {featuredMedia &&
          featuredMedia.localFile &&
          !showSlider &&
          !showAsBanner && (
            <div>
              <Img
                fluid={featuredMedia.localFile.childImageSharp.fluid}
                alt={homePageNode.featured_media.alt_text}
              />
            </div>
          )}
        <WrapperPadded>
          <Steps />
        </WrapperPadded>

        <WrapperDesktop>
          {showPlayForFree && (
            <PlayForFree
              title={title}
              iframe={iframe?.source_url}
              video_cover={video_cover}
              temp={showPlayForFree}
            />
          )}

          {showDemoGames && (
            <LazyLoad once={true} offset={100}>
              <Demo10Games duration={300000} />
            </LazyLoad>
          )}
        </WrapperDesktop>

        {/*<WrapperPadded>
          {homePageNode.content &&
          <ContentWrapper>
            <div dangerouslySetInnerHTML={{ __html: homePageNode.content }}/>
            <a id="home-more-less" className="button transparent-button show-more" href="#home-text-content" onClick={(e) => handleMoreClick(e)}>
              <span className="more-span">Read more</span>
              <span className="less-span">Read less</span>
            </a>
          </ContentWrapper>
          }
        </WrapperPadded>*/}

        <WrapperPadded>
          <WithdrawCommentsStaticLoadmore />
        </WrapperPadded>
      </div>
    </Layout>
  );
};

export default withLocation(HomePage);

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home-id" } }) {
      edges {
        node {
          title
          content
          wordpress_id
          comment_status
          featured_media {
            alt_text
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            top_slider {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1285) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            top_slider_mobile {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 750) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            demo_games_10 {
              id
            }

            play_for_free_title
            play_for_free_video_file_id {
              source_url
            }
            play_for_free_video_cover {
              alt_text
              title
              path
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            play_for_free_temp {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 260) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
